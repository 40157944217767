<template>
    <v-container fluid>
        <v-row>
            <v-container fluid>
                <v-card>
                    <v-card-text class="white">
                        <v-col cols="12" align="start">
                            <v-row class="grey lighten-4" align="start">
                                <v-col cols="12"> <strong>FILTROS</strong></v-col>
                                <v-col cols="12" sm="3">
                                    <v-menu
                                        ref="menu"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                    >
                                        <template #activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="fechaFiltro"
                                                hide-details="auto"
                                                dense
                                                label="FILTRAR POR RANGO - MES"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            />
                                        </template>
                                        <v-date-picker v-model="fechaFiltro" range no-title scrollable>
                                            <v-spacer />
                                            <v-btn text color="primary" @click="menu = false"> CERRAR </v-btn>
                                            </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-divider inset vertical></v-divider>
                                <v-col cols="6" sm="3">
                                    <v-autocomplete
                                        clearable
                                        v-model="isSelectCanal"
                                        dense
                                        hide-details="auto"
                                        outlined
                                        :items="listaCanal"
                                        item-text="canal"
                                        item-value="idCanal"
                                        label="Buscar por Canal"
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="6" sm="3">
                                    <v-autocomplete
                                        clearable
                                        v-model="isSelectOrigen"
                                        dense
                                        hide-details="auto"
                                        outlined
                                        :items="itemListaOrigen"
                                        item-text="origen"
                                        item-value="idOrigen"
                                        label="Buscar por Origen"
                                    >
                                        <template v-slot:item="data">
                                            <v-chip small color="secondaryTheme" class="caption px-2" dark>{{ data.item.canal }}</v-chip>
                                            &nbsp;&nbsp;<span class="caption">{{ data.item.origen }} </span>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="6" sm="4">
                                    <v-autocomplete
                                        clearable
                                        v-model="isSelectDepartamento"
                                        dense
                                        hide-details="auto"
                                        outlined
                                        :items="departamentoAssign"
                                        item-text="descripcion"
                                        item-value="idDepa"
                                        label="Buscar por Departamento"
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="6" sm="3">
                                    <v-autocomplete
                                        clearable
                                        v-model="isSelectProvincia"
                                        dense
                                        hide-details="auto"
                                        outlined
                                        :items="provincias"
                                        item-text="descripcion"
                                        item-value="idProv"
                                        label="Buscar por Provincia"
                                    >
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card-text>
                </v-card>
            </v-container>
        </v-row>
        <v-row class="pa-4">
            <!-- <v-container> -->
            <v-row>
                <v-col cols="6">
                    <v-card>
                        <v-card-title dark>
                            <strong>REPORTE PANEL PROSPECTOS</strong>
                            <v-spacer />
                            <v-btn class="mx-1" small color="green" dark @click="downloadHandle(1)">
                                DESCARGAR <v-icon right dark> mdi-arrow-down-box </v-icon>
                            </v-btn>
                        </v-card-title>
                    </v-card>
                </v-col>
                <v-col cols="6">
                    <v-card>
                        <v-card-title dark>
                            <strong>REPORTE ORIGEN/PROSPECTO</strong>
                            <v-spacer />
                            <v-btn class="mx-1" small color="green" dark @click="downloadHandle(2)">
                                DESCARGAR <v-icon right dark> mdi-arrow-down-box </v-icon>
                            </v-btn>
                        </v-card-title>
                    </v-card>
                </v-col>
                <v-col cols="6">
                    <v-card>
                        <v-card-title dark>
                            <strong>REPORTE ORIGENES / ESTADOS</strong>
                            <v-spacer />
                            <v-btn class="mx-1" small color="green" dark @click="downloadHandle(3)">
                                DESCARGAR <v-icon right dark> mdi-arrow-down-box </v-icon>
                            </v-btn>
                        </v-card-title>
                        <!--                     <v-card-text> <MixChart height="100%" width="100%" id="2" /> </v-card-text>
 -->
                    </v-card>
                </v-col>
                <!--                 <v-col cols="6">
                    <v-card>
                        <v-card-title dark>
                            <strong>REPORTE ESTADOS</strong>
                            <v-spacer />
                            <v-btn class="mx-1" small color="green" dark @click="downloadHandle(3)">
                                DESCARGAR <v-icon right dark> mdi-arrow-down-box </v-icon>
                            </v-btn>
                        </v-card-title>
                              <v-card-text> <MixChart height="100%" width="100%" id="2" /> </v-card-text>

                    </v-card>
                </v-col>
            </v-row>
                       </v-container>
 -->
            </v-row>
        </v-row>
    </v-container>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { exportXLSX } from '@/utils'
import { mixins } from '@/mixins/mixin.js'
import MixChart from '@/components/Charts/MixChart'
import { renameKeys } from '@/utils/genericUtils'
import { format, addDays, subDays, differenceInDays } from 'date-fns'

export default {
    components: {
        MixChart
    },
    mixins: [mixins],
    data() {
        return {
            fechaFiltro: [format(subDays(new Date(), 30), 'YYYY-MM-DD'), format(new Date(), 'YYYY-MM-DD')],
            isSelectDepartamento: null,
            isSelectProvincia: null,
            isSelectCanal: null,
            isSelectOrigen: null,
            isNumeroDocumento: null,
            numeroDocumento: null,
            itemListaOrigen: [],
            menu: false,

            openDialog: false,
            openDelete: false,
            openDocumento: false,
            loading: false,

            itemReporte: [
                { id: 1, text: 'Panel-Prospecto' },
                { id: 2, text: 'Origen-Prospecto' },
                { id: 3, text: 'Origen' },
                { id: 4, text: 'Estados' }
            ]
        }
    },
    watch: {
        isSelectCanal(val) {
            if (val) {
                console.log('this.isSelectCanal', val)
                this.itemListaOrigen = this.listaOrigen.filter((x) => x.idCanal == val)
            } else {
                this.itemListaOrigen = []
            }
        },
        async isSelectDepartamento(val) {
            console.log('isSelectDepartamento ', val)
            if (val) {
                await this.handleGetProvincias({ id: val })
            }
        }
    },
    computed: {
        ...mapGetters('usuarios', ['listaCanal', 'optionalRole']),
        ...mapGetters('ubigeo', ['departamentoAssign']),
        ...mapState('usuarios', ['listaOrigen']),
        ...mapState('utilities', ['tipoDocumento']),
        ...mapState('ubigeo', ['provincias', 'distritos'])
    },
    methods: {
        ...mapMutations({
            OPEN_MODAL: 'mainUI/OPEN_MODAL',
            OPEN_BAR: 'mainUI/OPEN_BAR'
        }),
        ...mapActions({
            handleGetPanelProspectos: 'reportes/reportePanelProspectos',
            handleOrigenes: 'reportes/reporteOrigenes',
            handleEstados: 'reportes/reporteEstados',
            handleGetDepartamentos: 'ubigeo/obtenerListaDepartamentos',
            handleGetProvincias: 'ubigeo/obtenerListaPronvincias',
            handleGetDistritos: 'ubigeo/obtenerListaDistritos',
            handleOrigenProspecto: 'reportes/reporteOrigenProspecto',
            handleOrigenEstadoProspecto: 'reportes/reporteOrigenEstadoProspecto'
        }),

        async downloadHandle(id) {
            this.$store.commit('mainUI/OPEN_MODAL', { state: true, text: 'Buscando Información' })

            this.loading = true
            const reporte = this.itemReporte.find((x) => x.id == id)
            let headers = []
            let data = []
            const body = {
                fechaFiltro: this.fechaFiltro,
                idDepartamento: this.isSelectDepartamento,
                idCanal: this.isSelectCanal,
                idOrigen: this.isSelectOrigen,
                idProvincia: this.isSelectProvincia,
                numeroDocumento: this.numeroDocumento
            }
            console.log('here -- ', body)
            if (id == 1) {
                data = await this.handleGetPanelProspectos(body)
                if (data.length > 0) {
                    headers = Object.keys(data[0])
                }
            } else if (id == 2) {
                headers = ['idOrigen', 'origen', 'idCanal', 'canal', 'cantidad']
                data = await this.handleOrigenProspecto(body)
            } else if (id == 3) {
                const reporteEstado = await this.handleOrigenEstadoProspecto(body)
                headers = reporteEstado.header
                data = reporteEstado.data
                // data = this.listaOrigenes
            } else if (id == 4) {
                headers = ['idOri', 'estado', 'idEstado', 'cantidad']
                //data = this.listaEstdos
            }

            const filename = `REPORT_${reporte.text}_${this.fechaFiltro}_${Date.now()}`
            this.$store.commit('mainUI/OPEN_MODAL', { state: false })
            exportXLSX({ headers, campos: headers, arrayData: data }, { filename })
            this.loading = false
        },
        getInfoSlash(value) {
            console.log('value ', value)
            if (value) {
                return value.split('|')
            } else {
                return ['', '', '', '']
            }
        },
        async fnHandleAll() {
            console.log('all')

            this.listaOrigenes = await this.handleOrigenes({ fechaFiltro: this.fechaFiltro })

            this.listaEstdos = await this.handleEstados({ fechaFiltro: this.fechaFiltro })
        }
    },
    async created() {
        await this.handleGetDepartamentos()
    },
    async created() {
        const oRoles = this.optionalRole
        let idDepas = [null]
        if (!oRoles.allLocation) {
            idDepas = oRoles.idDepartamento
            this.isSelectDepartamento = idDepas[0]
        }
        await this.handleGetDepartamentos()
    }
}
</script>
<style scoped>
.chart-container {
    position: relative;
    width: 100%;
    height: calc(100vh - 84px);
}
</style>
